.main-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content {
    display: flex;  
    width: 100%;
    flex: 1;
}

.MuiInputBase-input {
    height: unset!important;
}

.site-content {
    width: 100%;
    margin: 25px;
}

.outer-content {
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;
}
