
html, body {
    font-family: sans-serif;
}

body {
    margin: 0;
    padding: 0;
}

/* html, body {
    height: 100vh
}

#root {
    height: 100vh;
} */
